<template>
  <header id="header" class="py-4">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-auto me-auto">
          <a @click="goEvents">
            <img src="../assets/images/logo-3.svg" alt="logo">
          </a>
        </div>
        <!--/col()-->
        <div class="col-auto">
          <a @click="doAccount">
            <img src="../assets/images/user-2.svg" alt="icon">
          </a>
        </div>
        <!--/col()-->
      </div>
      <!--/row-->
    </div>
    <!--/container-fluid-->
  </header>
  <!--/header-->
  <div class="main-height playerScreen d-flex flex-column justify-content-md-center justify-content-center">
    <div className="playerPart">
      <div className="account-heading mb-2">
        <strong>Playing now:</strong> {{ eventTitle }}
      </div>
      <video-player
          id="VideoPlayer"
          :class="[
          'vjs-big-play-centered',
        ]" :style="{ backgroundColor: 'black'}"
          :src=configVideoJS.src
          :autoplay=configVideoJS.autoplay
          :volume=configVideoJS.volume
          :controls=configVideoJS.controls
          :muted=configVideoJS.muted
          :width=configVideoJS.width
          :height=configVideoJS.height
          :fluid=configVideoJS.fluid
          :loop=configVideoJS.loop
          :crossorigin=configVideoJS.crossorigin
          :disablePictureInPicture=configVideoJS.disablePictureInPicture
          :preferFullWindow=configVideoJS.preferFullWindow
          :playsinline=configVideoJS.playsinline
          :plugins=configVideoJS.plugins
          :html5=configVideoJS.html5
          :options=configVideoJS.options
          @ready="handlePlayerReady"
          @mounted="handleMounted"
          @unmounted="handleUnmounted"
          @error="handleError"
          @emptied="handleEmptied"
          @abort="handleAbort"
          @suspend="handleSuspend"
          @stalled="handleStalled"></video-player>
    </div>
  </div>
  <div class="">
    <footer id="footer" class=" py-3 text-white fixed-bottom ps-4">
      <div class="container-fluid"> © {{ new Date().getFullYear() }} Procplayer.tv </div>
    </footer>
  </div>
</template>

<script >
import {defineComponent, shallowReactive} from 'vue'
import VueSimpleAlert from 'vue3-simple-alert';
import UsersService from "@/services/users-service";
import AuthService from "@/services/auth-service";
import SessionService from "@/services/session-service"
import UserAgentParser from '@/utils/user-agent-parser'
import { VideoPlayer } from '@videojs-player/vue'
import 'videojs-contrib-quality-levels'
import videojsqualityselector from 'videojs-hls-quality-selector'
import 'video.js/dist/video-js.css'
import videojslandscapefullscreen from 'videojs-landscape-fullscreen'
import { event } from "vue-gtag";
import LeagueService from "@/services/league-service";

export default defineComponent({
  name: 'Player',
  components: {
    // Header,
    VideoPlayer
  },
  setup() {
    const playerPage = () => {
      event('player', { method: 'Google' })
    }
    return {
      playerPage
    }
  },

  data() {
    return {
      userProfile: null,
      userPlan: null,
      interval: null,
      streamUrl: "",
      baseStreamUrl: "",
      channelName: "",
      handleErrorOnce: false,
      player: null,
      eventTitle: "",
      configVideoJS: null,
      userAgent: "",
      deviceType: null,
      deviceOs: null,
      appVersion: "",
//      videoStarted: true,
      channel: null,
      dynamicData: []
    }
  },
  methods: {
    channelDisconnects: function() {
      //this.videoStarted = false;
      VueSimpleAlert.alert("Channel is not available. Try again later.", "Player error")
      this.$router.push('/events')
    },
    handleMounted: function (payload) {
      //console.log('----handleMounted', payload)
      this.player = payload.player
    },
    goEvents () {
      this.$router.push('/events')
    },
    handleUnmounted: function () {
      //console.log('---handleUnmounted')
    },
    handlePlayerReady: function (event) {
      //console.log('---handlePlayerReady', event)
      if (this.userProfile.videoQuality === undefined) {
        this.player.hlsQualitySelector = videojsqualityselector;
        this.player.hlsQualitySelector({
          displayCurrentQuality: true,
          placementIndex: 2
        })
      }
      this.player.landscapeFullscreen = videojslandscapefullscreen;
      this.player.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          exitOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: false
        }
      })
      this.player?.ready(() => {
        const tech = this.player.tech();
        if (tech && tech.vhs && tech.vhs.xhr) {
          tech.vhs.xhr.beforeRequest = (options) => {
            //check if playlist request
            const newSkValue = localStorage.getItem('sk');
            if (newSkValue !== null) {
              options.uri = options.uri.replace(/&sk=[^&]*$/, `&sk=${newSkValue}`);
            }
            return options;
          };
        }
      });
    },
    handleError: function (event) {
      //console.log('---handleError', event)
      this.channelDisconnects()
    },
    handleEmptied: function (event) {
      //console.log('---handleEmptied', event)
    },
    handleSuspend: function (event) {
      //console.log('---suspend', event)
    },
    handleAbort: function (event) {
      //console.log('---abort', event)
    },
    handleStalled: function (event) {
      //console.log('---stalled', event)
    },
    getUserLatest: function () {
      UsersService.getUserLatest().then((newUserLatest) => {
        if (newUserLatest) {
          this.compareUserLatest(newUserLatest)
        }
      })
    },
    compareUserLatest: function (newUserLatest) {
      if (localStorage.getItem('userLatest') && localStorage.getItem('userLatest') !== JSON.stringify(newUserLatest)) {
        const oldUserLatest = JSON.parse(localStorage.getItem('userLatest'))
        // compare user latest
        if (oldUserLatest.loginId !== newUserLatest.loginId) {
          AuthService.signOut().then(() => {
            localStorage.setItem('sessionExpired', "true")
            this.$router.push('/signin')
          })
        }
        if (oldUserLatest.userProfile !== newUserLatest.userProfile
            || oldUserLatest.userPlans !== newUserLatest.userPlans) {
          // update user profile
          UsersService.getUserProfile().then(() => {
            if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
            if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
            if (this.userPlan.trial && (this.userProfile.trialOverallTime <= 0 || this.userProfile.trialTodayTime <= 0)) {
              this.$router.push('/plans')
            }
            if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
          })
        }
        if (oldUserLatest.payments !== newUserLatest.payments) {
          // update user payments
        }
        if (oldUserLatest.statics !== newUserLatest.statics) {
          UsersService.getUserStaticData()
        }
        if (oldUserLatest.channels !== newUserLatest.channels) {
          // update leagues
          LeagueService.getLeagueList().then(() => {
            if (localStorage.getItem('leagueList')) {
              this.leaguesList = JSON.parse(localStorage.getItem('leagueList'))
              this.eventsList = []
              this.leaguesList.forEach(league => {
                // Check if the leagueId matches
                if (league.application === "procricket") {
                  league.events.forEach(event => {
                    this.eventsList.push(event);
                  });
                  if (this.eventsList.length > 0) {
                    this.mainEvent = this.eventsList.shift();
                  }
                }
              });
            }
          })
        }
      }
      this.error = newUserLatest.errorText
      localStorage.setItem('userLatest', JSON.stringify(newUserLatest))
    },
    doAccount() {
      this.$router.push('/account')
    },
    setupPlayer() {
      if (this.channel.edgePort === "" || this.channel.edgePort === undefined) {
        this.baseStreamUrl = "https://" + this.dynamicData.edgeAddress + "/"
      } else {
        this.baseStreamUrl = "https://" + this.dynamicData.edgeAddress + ":" + this.channel.edgePort + "/"
      }
      this.streamUrl = this.channel.appName + "/" + this.checkUndefined(this.channel.hlsPrefix) + this.channel.channelName
          + this.checkUndefined(this.channel.hlsSufix)
          + this.checkUndefined(this.dynamicData.videoQuality)
          + "/playlist.m3u8?id=" + this.channel.channelId + "&pk=" + this.dynamicData.pk + "&userId="
          + this.userProfile.userId + "&vidictid=" + this.dynamicData.vidictId
      const skValue = localStorage.getItem('sk');
      if (skValue !== null) {
        this.streamUrl += "&sk=" + localStorage.getItem('sk')
      }
      this.streamUrl = this.baseStreamUrl + this.streamUrl;
      this.configVideoJS = shallowReactive({
        src: this.streamUrl,
        autoplay: true,
        width: 800,
        height: 600,
        volume: 0.8,
        controls: true,
        fluid: true,
        muted: false,
        loop: false,
        crossorigin: 'anonymous',
        responsive: true,
        preferFullWindow: true,
        playsinline: false,
        disablePictureInPicture: true,
        plugins: {
        },
        html5: {
          vhs: {
            maxPlaylistRetries: 3,
            enableLowInitialPlaylist: false,
            limitRenditionByPlayerDimensions: false,
            overrideNative: true,
            useNetworkInformationApi: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        },
        options: {controlBar: { children: ['playToggle','liveDisplay', 'volumePanel', 'fullscreenToggle']} }
      })
    },
    leavePage: function () {
      clearInterval(this.interval);
      //if (this.videoStarted === true) {
      this.player.dispose();
      const session = UserAgentParser.createSession(this.dynamicData.vidictId, this.deviceOs, this.deviceType, this.appVersion, this.userAgent);
      SessionService.updateAndDisconnectSession(session);
      //}
    },
    checkUndefined (inputString) {
      if (typeof inputString === 'undefined' || inputString === '' || inputString === null) {
        return '';
      } else {
        return inputString;
      }
    }
  },
  mounted() {
    this.getUserLatest();
    this.interval = setInterval(function () {
      this.getUserLatest();
    }.bind(this), 30000);
  },
  beforeMount() {
    clearInterval(this.interval);
    if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
    if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
    if (localStorage.getItem('eventTitle')) this.eventTitle = localStorage.getItem('eventTitle')
    if (localStorage.getItem('channel')) this.channel = JSON.parse(localStorage.getItem('channel'))
    if (localStorage.getItem('dynamicData')) {
      this.dynamicData = JSON.parse(localStorage.getItem('dynamicData'))
      this.setupPlayer();
    } else {
      UsersService.getDynamicData().then((data) => {
        this.dynamicData = data;
        this.setupPlayer();
      });
    }
    if (localStorage.getItem('coupon')) {
      localStorage.setItem('coupon', null)
    }
    this.userAgent = navigator.userAgent;
    this.deviceType = UserAgentParser.extractDeviceType(this.userAgent);
    this.deviceOs = UserAgentParser.extractDeviceOs(this.userAgent);
    this.appVersion = UserAgentParser.getAppVersion(this.userAgent, this.deviceType);
  },

  beforeUnmount() {
    this.leavePage()
  },

  computed: {
  }
})
</script>

<style scoped>
@import '../assets/css.css?v=1.0.3';
.main-height {margin-bottom: 3%;display: flex;justify-content: center;align-items: center;text-align: center;min-height: 80vh;}
.playerPart{margin:0px auto;width: 60vw;}
.account-heading {color: #3f3f3f !important;}

</style>
