import apiClient from '@/utils/api-client'
import {Session} from "@/model/models";


class SessionService {

    updateAndDisconnectSession(session: Session) {
        localStorage.removeItem('dynamicData');
        return new Promise<boolean>((resolve, reject) => {
            apiClient.post('/api/v1/user/session/update-disconnect/', session ).then((res) => {
                if (res.status === 200 && res.data !== null) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new SessionService()
